export default {
  blogerName: 'DZK40CM',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://m.youtube.com/channel/UCIYkWasTNJIArXOdiNpEMgQ',
    },
    {
      name: 'tiktok',
      url: 'https://www.tiktok.com/@dizkiltt',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/dizkilrv/',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs.com/c8f6e2ee7',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c333677b1',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cd0c73269',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c1163637d',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c19979439',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ce13b6b98',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cc08f99f0',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/ca0490b06',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
  ],
  headerText: 'Cadastre-se com um código promocional <strong>DZK40CM</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'DZK40CM',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
